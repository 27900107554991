<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Dashboard component
 */
export default {
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Nazox",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      cpuused: 50,
      cpu_progresscolor: "#20a53a",
      memory: 0,
      memory_progresscolor: "#20a53a",
      hostname: "",
      statistics: [],
      salesdata: [],
      options: {
        chart: {
          id: "dashchart",
        },
        xaxis: {
          categories: [],
        },
      },
      series: [],
      userinfo: [],
      iplist: {
        ipv4: [],
        ipv6: "",
      },
      noticelist: [],
      timer: null,
      todolist: [],
      stocklist: [],
    };
  },
  mounted() {
    var that = this;
    that.title = that.$t("dashboard.text");
    that.items = [
      {
        text: that.$t("dashboard.text"),
        href: "/",
      },
    ];
    that.userinfo = JSON.parse(localStorage.getItem("user"));
    if (localStorage.getItem("notice")) {
      that.noticelist = JSON.parse(localStorage.getItem("notice"));
    }
    that.timer = setInterval(() => {
      that.getsalesdata();
    }, 10000);
    that.updateprintersetting();
    that.gettodolist();
    that.websocket.ws.onmessage = function (res) {
      let wsdata = JSON.parse(res.data);

      if (wsdata.action === "readcard") {
        if (that.cardhex) {
        } else {
          that.$axios
            .post(
              that.apiuri,
              {
                action: "finduser",
                hex: wsdata.uid,
              },
              {
                headers: { Openid: that.userinfo.openid },
              }
            )
            .then(function (response) {
              let res = response.data;
              if (res.status == 200) {
                that.$message({
                  message: that.$t("global.ws.finduser"),
                  type: "success",
                });
                that.$router.push({
                  path: "/user/detail",
                  query: { id: res.data.id },
                });
              } else {
                that.cardhex = wsdata.uid;
                if (!that.bindcardhex && that.bindcarduid) {
                  that
                    .$confirm(
                      that.$t("global.ws.userbindcard"),
                      that.$t("global.delete.sureyouaction"),
                      {
                        confirmButtonText: that.$t("global.button.ok"),
                        cancelButtonText: that.$t("global.button.cancel"),
                        type: "warning",
                      }
                    )
                    .then(() => {
                      that.$axios
                        .post(
                          that.apiuri,
                          {
                            action: "bindnewcard",
                            hex: that.cardhex,
                            uid: that.bindcarduid,
                          },
                          {
                            headers: { Openid: that.userinfo.openid },
                          }
                        )
                        .then(function (response) {
                          that.loading = false;
                          if (response.data.status == 200) {
                            that.$message({
                              message: that.$t("global.ws.bindsuccess"),
                              type: "success",
                            });
                            that.$router.push({
                              path: "/user/list",
                            });
                          } else if (response.data.status == 403) {
                            that.$message({
                              message: that.$t("global.delete.auth"),
                              type: "warning",
                            });
                          } else {
                            that.$message.error(that.$t("global.delete.500"));
                          }
                          that.getadminlist();
                        })
                        .catch(function (error) {
                          console.log(error);
                        });
                    })
                    .catch(() => {});
                } else {
                  that.$router.push({
                    path: "/user/add",
                    query: { id: wsdata.uid },
                  });
                }
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
      if (wsdata.action === "notice") {
        wsdata["time"] = Date.parse(new Date());
        wsdata["read"] = 0;
        that.noticelist.unshift(wsdata);
        localStorage.setItem("notice", JSON.stringify(that.noticelist));

        if (wsdata.type2 === "device") {
          that.$notify({
            title: that.$t("global.notice." + wsdata.status),
            message:
              that.$t("global.notice.device", { device: wsdata.edid }) +
              " " +
              that.$t("global.notice." + wsdata.type1),
            type: wsdata.status,
          });
        } else if (wsdata.type2 === "service") {
          that.$notify({
            title: that.$t("global.notice." + wsdata.status),
            message:
              that.$t("global.notice.service", {
                service: that.$t("global.notice.server." + wsdata.service),
              }) +
              " " +
              that.$t("global.notice." + wsdata.type1),
            type: wsdata.status,
            duration: 0,
          });
        }
      } else if (wsdata.type2 === "stock") {
        that.$notify({
          title: that.$t("global.notice." + wsdata.status),
          message: that.$t("global.notice.stock", {
            edid: wsdata.edid,
            stock: wsdata.stock,
          }),
          type: wsdata.status,
        });
      } else if (wsdata.type2 === "sensor") {
        that.$notify({
          title: that.$t("global.notice." + wsdata.status),
          message: that.$t("global.notice.sensor", {
            edid: wsdata.edid,
            num: wsdata.num,
          }),
          type: wsdata.status,
        });
      }
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    handdleMsg(msg) {},
    getcpuused() {
      if (this.cpuused >= 80) {
        this.cpu_progresscolor = "#f7b851";
      } else if (this.cpuused >= 90) {
        this.cpu_progresscolor = "#ff0017";
      } else {
        this.cpu_progresscolor = "#20a53a";
      }
    },
    getmemory() {
      if (this.memory >= 80) {
        this.memory_progresscolor = "#f7b851";
      } else if (this.memory >= 90) {
        this.memory_progresscolor = "#ff0017";
      } else {
        this.memory_progresscolor = "#20a53a";
      }
    },
    getsalesdata() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getsalesdata",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.salesdata = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    gettodolist() {
      var that = this;
      that.todolist = [];
      that.$axios
        .post(
          that.apiuri,
          {
            action: "gettodolist",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.todolist = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });

      // console.log(that.$t("menuitems.clouds.printer.text"))
    },
    updateprintersetting(){
      var that=this
      that.$axios
        .post(
          that.apiuri,
          {
            action: "reloadprinter"
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          // that.getprintersetting();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-6 col-lg-6 col-sm-6">
        <div class="card card-block card-stretch card-height">
          <div class="card-body topmodel">
            <h5>{{ $t("dashboard.waitjob") }}</h5>
            <div class="showbox">
              <el-empty
                :description="$t('dashboard.nostatistics')"
                v-if="todolist.length === 0"
              ></el-empty>
              <el-table v-else :data="todolist" style="width: 100%" :show-header="false">
                <el-table-column :label="$t('dashboard.waitjob')">
                  <template slot-scope="scope">
                    <span v-if="scope.row.type == 'account'">
                      {{
                        $t("dashboard.todolist.account", {
                          username: scope.row.username,
                        })
                      }}
                      <span v-for="(val,idx) in scope.row.msg" :key="idx">
                        {{ $t("dashboard.todolist." + val) }}
                      </span>
                    </span>
                    <span v-else-if="scope.row.type == 'device'">
                      {{
                        $t("dashboard.todolist.devices", {
                          device: scope.row.ED_id,
                        })
                      }}
                      <span v-for="(val,idx) in scope.row.msg" :key="idx">
                        {{ $t("dashboard.todolist." + val) }}
                      </span>
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-6">
        <div class="card card-block card-stretch card-height">
          <div class="card-body topmodel">
            <h5>{{ $t("dashboard.stockjob") }}</h5>
            <div class="showbox">
              <el-empty
                :description="$t('dashboard.nostatistics')"
                v-if="stocklist.length === 0"
              ></el-empty>
              <el-table v-else :data="stocklist" style="width: 100%" :show-header="false">
                <el-table-column :label="$t('dashboard.waitjob')">
                </el-table-column>
              </el-table>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h5>{{ $t("dashboard.statistics") }}</h5>
            <el-empty
              :description="$t('dashboard.nostatistics')"
              v-if="salesdata.length === 0"
            ></el-empty>
            <el-table v-else :data="salesdata" style="width: 100%">
              <el-table-column
                prop="date"
                :label="$t('dashboard.date')"
                width="180"
              >
              </el-table-column>
              <el-table-column
                prop="sum"
                :label="$t('dashboard.ordersum')"
                width="180"
              >
              </el-table-column>
              <el-table-column :label="$t('dashboard.sales')">
                <template slot-scope="scope">
                  <span>{{ scope.row.sale }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('dashboard.salestotal')">
                <template slot-scope="scope">
                  <span>{{ scope.row.total }} ML</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style>
.topmodel {
  height: 300px;
  position: relative;
  overflow: hidden;
}
.showbox {
  height: 250px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.showbox::-webkit-scrollbar {
  width: 4px;
}
.showbox::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.2;
}
.showbox::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
}
</style>